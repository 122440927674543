import React from 'react';
import './BannerStyles.scss'
import logo from '../../Assets/Images/ab-solutions.png'

function Banner(){
    return(
        <div id='backgroundimagewidth' id='home'>
            <div id='divbackgroundHome'>
                <img  id='imagehome'src={logo} alt= 'backgroundimage'/>
                <div className="container-text">
                    <h1 id='textBackgroundImage'>Votre informaticien de proximité</h1>
                    <p id='pBackgroundImage'>Déplacement Gratuit sur tout le var</p>
                </div>

            </div>
        </div>
    )
}

export default Banner