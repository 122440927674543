import React from "react";
import imgsociete from "../../Assets/Images/it-services.jpg"
import './CardSocieteStyles.scss'

const CardSociete = () =>{
    return(
        <>
            <div className='containerFlex'>
                <div className='containerSociete'>
                    <h2 className='cardSocieteT-title'>La Société</h2>
                    <div className='containerText'>
                        <p className='cardSocieteText'><strong>Antibug Solutions</strong>, le pro du dépannage informatique sur <strong>tout le Var (83), depuis 2008</strong></p>
                        <p className='cardSocieteText'>Faites appel <strong>aux experts informatiques d’Antibug Solutions.</strong></p>
                        <p className='cardSocieteText'>Nous disposons <strong>de trois agences</strong> dans le Var pour plus de réactivité et de proximité.</p>
                        <p className='cardSocieteText'>L’une se trouve à <strong>La Valette</strong>, une à <strong>Flassans sur Issole</strong>, près de Brignoles, l’autre à <strong>Draguignan</strong></p>
                        <p className='cardSocieteText'>Nous vous assurons de notre <strong>réactivité</strong> par nos techniciens sur le terrain.</p>
                        <p className='cardSocieteText'>Pour bénéficier d’une analyse de votre besoin et d’un tarif personnalisé, <strong>contactez-nous.</strong></p>
                    </div>

                </div>
                <div className='containerSocieteImage'>
                 <img  className='image' src={imgsociete} alt="image"/>
                </div>
            </div>

        </>
    )

}

export default CardSociete;