import React from "react";
import './NavBarStyles.scss';
import {HashLink as Link} from 'react-router-hash-link';
import logo from '../../Assets/Images/ab-solutions.png'
const NavBar = () => {
    return (
        <>
            <header>
                <div className='NavBar'>
                    <div className='container-logo-title'>
                        <div className='logo'>
                            <img className='logoImages' src={logo}/>
                        </div>

                    </div>


                    <div className='buttonLink'>
                        <Link to="#home" className='HeaderLink'>ACCEUIL</Link>
                        <Link  to="#contact" className='HeaderLink'> CONTACT</Link>
                        <Link to="#service" className='HeaderLink'>SERVICE A LA PERSONNE</Link>
                        {/*<Link to="#horaire" className='HeaderLink'> </Link>*/}
                        {/*<Link to="#Contact"> Contact</Link>*/}

                    </div>

                </div>

            </header>


        </>
    )
}

export default NavBar;