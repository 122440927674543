import React from "react";
import './ContainerServiceStyles.scss'
import imageService from '../../Assets/Images/service-a-la-personne.png'
const ContainerService = () => {
    return(
        <>
            <div className='containerBackground' id='service'>
                <div className='containerService'>
                    <img  className='Image-Service' src={imageService} alt='image'/>
                    <div className='containerTextService'>
                        <h4 className='titleService'>Comment bénéficier de la réduction fiscale?</h4>
                        <p>Avant le 31 janvier de chaque année nous vous ferons parvenir une attestation fiscale comprenant les montants que nous vous aurons facturés dans l’année écoulée.Cette attestation est à joindre avec nos factures à votre déclaration d’impôt sur le revenu. Ainsi, 50% des sommes facturées dans l’année seront déduites de votre impôt l’année suivante !Le montant des prestations est plafonné à 1000 € par an et par foyer fiscal</p>
                    </div>
                </div>

            </div>
        </>
    )
};

export default ContainerService