
import React, { useState } from "react";
import './TemoignageClientStyle.scss'
import Comment from '../../Assets/Images/comment.png'
import flecheG from '../../Assets/Icon/fleche gauche.png'
import flecheD from '../../Assets/Icon/fleche droite.png'

const TemoignageClient = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const testimonials = [
        {
            text: "Je fais appel à la société Antibug Solutions depuis 2008. Je suis toujours aussi contente de leurs services. Très professionnel, très réactif.",
            author: "Pascale",
        },
        {
            text: "Denis est venu me dépanner, c’est mon sauveur, j’ai retrouvé internet et j’ai pu discuter par skype avec ma petite fille.",
            author: "Marcelle",
        },
        {
            text: "Mon ordinateur ne démarrait plus, après mon appel chez Antibug, un technicien s’est déplacé le lendemain et a remis en état mon ordinateur.",
            author: "Michel",
        },
        {
            text: "Je suis très satisfait  du service clientèle de Antibug Solutions. L'équipe a été très professionnelle et a répondu rapidement à toutes mes questions et préoccupations.",
            author: "Renée",
        },
        {
            text: "Antibug Solutions m'a aidé à résoudre un problème technique complexe que je n'arrivais pas à résoudre moi-même. Leur équipe a été très patiente et compétente, et j'ai été impressionné par leur expertise technique.",
            author: "Gérard",
        },
        {
            text: "Je suis tellement reconnaissant pour l'aide que j'ai reçue de Antibug Solutions. J'étais tellement frustré avec mon ordinateur qui ne fonctionnait pas correctement et je n'avais aucune idée de comment résoudre le problème. L'équipe de Antibug Solutions  a rapidement répondu à ma demande d'assistance et a travaillé avec moi pour identifier la cause sous-jacente de mes problèmes. Ils m'ont fourni des instructions claires pour résoudre le problème et ont été très patients et professionnels tout au long du processus. Je recommande fortement Antibug Solutions pour tous vos besoins en informatique.",
            author: "Serge",
        },
        {
            text: "Je suis extrêmement impressionné par les services de Antibug Solutions. J'ai eu un problème avec mon ordinateur portable et je devais le réparer rapidement pour pouvoir travailler à domicile. J'ai contacté Antibug Solutions et ils ont immédiatement compris mon problème. Ils m'ont fourni des instructions détaillées pour résoudre le problème moi-même, mais quand j'ai eu du mal à suivre les instructions, ils ont pris le contrôle de mon ordinateur à distance et ont résolu le problème en quelques minutes. Leur équipe est incroyablement professionnelle et compétente, et je suis très heureux de les avoir comme fournisseur de services informatiques",
            author: "Pierre",
        },
    ];

    const sliceStartIndex = activeIndex * 3;
    const slicedTestimonials = testimonials.slice(sliceStartIndex, sliceStartIndex + 3);

    const handleNextClick = () => {
        if (activeIndex === Math.floor(testimonials.length / 3)) {
            setActiveIndex(0);
        } else {
            setActiveIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrevClick = () => {
        setActiveIndex(prevIndex =>
            prevIndex === 0 ? Math.ceil(testimonials.length / 3) - 1 : prevIndex - 1
        );
    };
    const handleDotClick = (index) => {
        setActiveIndex(index);
    };
    const pageCount = Math.ceil(testimonials.length / 3);
    const renderDots = () => {
        const dots = [];
        for (let i = 0; i < pageCount; i++) {
            dots.push(
                <button
                    key={i}
                    className={activeIndex === i ? "dot active" : "dot"}
                    onClick={() => handleDotClick(i)}
                />
            );
        }
        return dots;
    };

    return (
        <>
            <div className='containerTemoignage'>
                <h2 className='titleTemoi'>Témoignages Clients</h2>
                <div className='containerClients'>
                    {slicedTestimonials.map((testimonial, index) => (
                        <div key={index} className='container'>
                            <img src={Comment} alt='Logo-Comment' />
                            <p>{testimonial.text}</p>
                            <p><strong>{testimonial.author}</strong></p>
                        </div>
                    ))}
                </div>
                <div className='carousel-controls'>
                    <img  className='flechDroite' src={flecheG} onClick={handlePrevClick} disabled={activeIndex === 0}/>
                    <img className='flechGauche' src={flecheD} onClick={handleNextClick} disabled={activeIndex === Math.floor(testimonials.length / 3)}/>
                </div>
                <div className="carousel-dots">
                    {testimonials.length > 3 &&
                        Array(Math.ceil(testimonials.length / 3)).fill().map((_, index) => (
                            <button
                                key={index}
                                className={activeIndex === index ? "dot active" : "dot"}
                                onClick={() => setActiveIndex(index)}
                            />
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default TemoignageClient;


// const testimonials = [
//     {
//         text: "Je fais appel à la société Antibug Solutions depuis 2008. Je suis toujours aussi contente de leurs services. Très professionnel, très réactif.",
//         author: "Pascale",
//     },
//     {
//         text: "Denis est venu me dépanner, c’est mon sauveur, j’ai retrouvé internet et j’ai pu discuter par skype avec ma petite fille.",
//         author: "Marcelle",
//     },
//     {
//         text: "Mon ordinateur ne démarrait plus, après mon appel chez Antibug, un technicien s’est déplacé le lendemain et a remis en état mon ordinateur.",
//         author: "Michel",
//     },
//     {
//         text: "Je suis très satisfait  du service clientèle de Antibug Solutions. L'équipe a été très professionnelle et a répondu rapidement à toutes mes questions et préoccupations.",
//         author: "Renée",
//     },
//     {
//         text: "Antibug Solutions m'a aidé à résoudre un problème technique complexe que je n'arrivais pas à résoudre moi-même. Leur équipe a été très patiente et compétente, et j'ai été impressionné par leur expertise technique.",
//         author: "Gérard",
//     },
//     {
//         text: "Je suis tellement reconnaissant pour l'aide que j'ai reçue de Antibug Solutions. J'étais tellement frustré avec mon ordinateur qui ne fonctionnait pas correctement et je n'avais aucune idée de comment résoudre le problème. L'équipe de Antibug Solutions  a rapidement répondu à ma demande d'assistance et a travaillé avec moi pour identifier la cause sous-jacente de mes problèmes. Ils m'ont fourni des instructions claires pour résoudre le problème et ont été très patients et professionnels tout au long du processus. Je recommande fortement Antibug Solutions pour tous vos besoins en informatique.",
//         author: "Serge",
//     },
//     {
//         text: "Je suis extrêmement impressionné par les services de Antibug Solutions. J'ai eu un problème avec mon ordinateur portable et je devais le réparer rapidement pour pouvoir travailler à domicile. J'ai contacté Antibug Solutions et ils ont immédiatement compris mon problème. Ils m'ont fourni des instructions détaillées pour résoudre le problème moi-même, mais quand j'ai eu du mal à suivre les instructions, ils ont pris le contrôle de mon ordinateur à distance et ont résolu le problème en quelques minutes. Leur équipe est incroyablement professionnelle et compétente, et je suis très heureux de les avoir comme fournisseur de services informatiques",
//         author: "Pierre",
//     },
// ];