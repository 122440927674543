import emailjs from 'emailjs-com';
import './EmailStyles.scss'
import carte from '../../Assets/Images/cartes.jpg'
import React, { useState } from 'react';
function ContactForm() {
    const [alertMessage, setAlertMessage] = useState('');
    function handleSubmit(event) {
        event.preventDefault();

        emailjs.sendForm('service_71ckrtd', 'template_vk2cty8', event.target, 'doGzWGUw06xrtZkbB')
            .then((result) => {
                alert('E-mail envoyé avec succès!');
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='containerEmail' id='contact'>
            <h2 className='titleEmail'> Nous contacter</h2>
            <div className='containerEmailService'>
                <div className='containerImage'>
                    <img className='imageCarte' src={carte} alt='cartes'/>
                    <p>Téléphone: +33.6.13.80.05.08</p>
                    <p className='textEmailFooter'>Addresse: 2027 chemin de carpanel
                        83340 Flassans sur issole</p>
                </div>

                <form onSubmit={handleSubmit}>
                    <label>
                        Nom:
                        <input type="text" name="user_name" />
                    </label>
                    <label>
                        Email:
                        <input type="email" name="user_email" />
                    </label>
                    <label>
                        Telephone:
                        <input type="tel" name="user_tel" />
                    </label>
                    <label>
                        Message:
                        <textarea  className='labelMessage' name="message" />
                    </label>
                    <button type="submit">Envoyer</button>
                </form>
                {alertMessage && <p>{alertMessage}</p>}
            </div>

        </div>
    );
}
export default ContactForm