import './HomePageStyles.scss';
import NavBar from "../../Components/NavBar/NavBar";
import Banner from "../../Components/Banner/Banner";
import CardHomePage from "../../Components/CardHomePage/CardHomePage";
import CardSociete from "../../Components/CardSociete/CardSociete";
import ContainerService from "../../Components/ContainerService/ContainerService";
import TemoignageClient from "../../Components/TemoignageClient/TemoignageClient";
import Equipe from "../../Components/Equipe/Equipe";
import Email from "../../Components/Email/Email";

const HomePage = () => {
  return (
    <>
     <NavBar/>
        <Banner/>
        <CardHomePage/>
        <CardSociete/>
        <ContainerService/>
        <TemoignageClient/>
        <Equipe/>
        <Email/>

    </>
  );
}

export default HomePage;
