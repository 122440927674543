import React from "react";
import logoIcon from '../../Assets/Images/colis.png'
import './CardHomePageStyles.scss'



const CardHomePage = () => {
    return(
        <>
        <div className='container-cards'>
            <div className='card'>
                <div className='Icon'>
                    <img  className="logoImg" src={logoIcon} alt="logo"/>
                </div>
                <h2 className="card-title"> ASSISTANCE, RÉPARATION ET DÉPANNAGE À DOMICILE</h2>
                <ul>
                    <li>mise à niveau de votre PC.</li>
                    <br/>
                    <li>Diagnostics de pannes, matérielles ou logicielles.</li>
                    <br/>
                    <li>Nettoyage virus, malware et autres infections, installation et configuration d’antivirus.</li>
                    <br/>
                    <li>Installation Windows ou ré installation avec sauvegarde de vos données si besoin.</li>
                    <br/>
                    <li>Optimisation Windows.</li>
                    <br/>
                    <li>mise à jour de pilotes des composants (carte vidéo, chipset, carte son etc.) et périphériques.</li>
                    <br/>
                    <li>Récupération de données.</li>
                    <br/>
                    <li>Configuration réseau domestique et professionnel / partage de données et périphériques.</li>
                </ul>
            </div>
            <div className='card'>
                <div className='Icon'>
                    <img  className="logoImg" src={logoIcon} alt="logo"/>
                </div>
                <h2 className="card-title"> APPRENDRE À UTILISER VOTRE NAVIGATEUR INTERNET</h2>
                <ul>
                    <li>Il vous suffit d’apprendre quelques règles de base en matière de navigation sur le Web.</li>
                    <br/>
                    <li>Par exemple l’utilisation des boutons de la barre d’outils Internet Explorer.pour réussir à naviguer plus facilement et plus rapidement.Ajouter au favoris..Supprimer l’historique de navigation, …</li>
                    <br/>
                </ul>
            </div>

            <div className='card'>
                <div className='Icon'>
                    <img  className="logoImg" src={logoIcon} alt="logo"/>
                </div>
                <h2 className="card-title"> ASSISTANCE, RÉPARATION ET DÉPANNAGE À DOMICILE</h2>
                <ul>
                    <li>mise à niveau de votre PC.</li>
                    <br/>
                    <li>Vous recherchez une formation en informatique et bureautique?</li>
                    <br/>
                    <li>ANTIBUG SOLUTIONS intervient sur le Var, directement à votre domicile afin de vous assister dans la prise en main de votre ordinateur.</li>
                    <br/>
                    <li>L’initiation informatique personnalisée Les cours d’initiation à l’informatique reste la formation la plus demandée dans le Var.</li>
                    <br/>
                    <li>Effectivement, maîtriser l’outil informatique est une nécessité professionnelle mais aussi dans la vie privée.</li>
                    <br/>
                    <li>Selon votre niveau, ANTIBUG SOLUTIONS vous propose une formation adaptée à votre domicile.</li>
                </ul>
            </div>

            <div className='card'>
                <div className='Icon'>
                    <img  className="logoImg" src={logoIcon} alt="logo"/>
                </div>
                <h2 className="card-title"> SE FORMER AUX LOGICIELS DE BUREAUTIQUE</h2>
                <ul>
                    <li>Une formation bureautique pour bien utiliser les logiciels tel que:</li>
                    <br/>
                    <li>Word, Excel, Powerpoint.Gagnez du temps dans la conception de vos documents..</li>
                    <br/>
                </ul>
            </div>


        </div>
        </>
    )


}

export default CardHomePage;