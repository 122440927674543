import React from "react";
import './EquipeStyles.scss'
import patrick from '../../Assets/Images/patrcik.png'
import denis from '../../Assets/Images/denis.jpg'
import cyril from '../../Assets/Images/cyril.jpg'
const Equipe = () => {
    return(
        <>
            <div className='containerEquipe'>
                <h2 className='titleEquipe'> Notre Equipe</h2>
                <div className='containerCardEquip'>
                    <div className='cardEquipe'>
                        <img  className='imageEquipe' src={patrick} alt='photo'/>
                        <p><strong>Patrick</strong></p>
                        <p className='pRoles'>Président Fondateur</p>
                    </div>
                    <div className='cardEquipe'>
                        <img  className='imageEquipe' src={denis} alt='photo'/>
                        <p><strong>Denis</strong></p>
                        <p className='pRoles'>Technicien Itinerant</p>
                    </div>
                    <div className='cardEquipe'>
                        <img  className='imageEquipe' src={cyril} alt='photo'/>
                        <p><strong>Cyril</strong></p>
                        <p className='pRoles'>Technicien Itinerant</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Equipe